$two-col-panel-break-1: 740px;

.two-col-panel {
	margin-top: var(--vertical-block-spacing);
	overflow: hidden;

	&--overflow {
		overflow: visible;
	}

	&--flip {
		.two-col-panel__container {
			@media (min-width: $two-col-panel-break-1) {
				flex-direction: row-reverse;
			}
		}

		.two-col-panel__background-image-shape {
			right: unset;
			left: -10rem;
		}

	}

	&--bg {
		margin-top: 0;
		padding: var(--container-spacing) 0;
		background-color: var(--color-three);
	}

	&--image {
		.two-col-panel__container {
			align-items: center;
		}
	}

	&--overline-spacing {
		@media (min-width: $two-col-panel-break-1) {
			.two-col-panel__item:last-of-type {
				padding-top: 2.8rem;
			}
		}
	}

	&__container {
		overflow: hidden;
		
		@media (min-width: $two-col-panel-break-1) {
			display: flex;
			justify-content: space-between;
		}
	}

	&__item {
		z-index: 3;

		@media (min-width: $two-col-panel-break-1) {
			width: calc(50% - 1.8rem);

			> * {
				&:first-child {
					margin-top: 0;
				}
			}
		}
	}

	&__overline {
		@include font-preset--13;
		color: var(--color-four);
		display: block;
		margin: 0 0 2ex;
	}

	&__heading {
		@include font-preset--2;
		margin-top: 0;
	}

	&__image {
		@media (max-width: $two-col-panel-break-1) {
			margin-top: 2.25rem;
		}
	}

	&__background-shape {
		top: calc(100% - 10rem);
		right: unset;
		left: -24rem;
		width: 55rem;
		bottom: unset;
	}

	&__background-image-shape {
		top: calc(50% - 18rem);
		right: -10rem;
		left: unset;
		width: 36rem;
		bottom: unset;
		z-index: -1;
	}
}

