.team-member-text {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;

  &__picture {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 5rem;
    overflow: hidden;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__body {
    width: calc(100% - 3.5rem);
    flex-grow: 1;
  }

  &__title {
    @include font-preset--12;
  }

  &__strapline {
    @include font-preset--15;
  }

  @media only screen and (min-width: 1024px) {
    &__picture {
      width: 4.3125rem;
      height: 4.3125rem;
    }

    &__body {
      width: calc(100% - 5.3125rem);
    }
  }
}