.container {
	width: 100%;
	margin: auto;
	padding-left: var(--container-spacing);
	padding-right: var(--container-spacing);

	&--medium {
		max-width: 62rem;
	}

	&--narrow {
		max-width: 56rem;
	}

	&--super-narrow {
		max-width: 46rem;
	}

	@media only screen and (min-width: 1300px) {
		&--mxw {
			margin: auto;
			max-width: 1280px;
		}
	}
	@media only screen and (min-width: 1500px) {
		&--mxw {
			max-width: 1440px;
		}
	}
}

.columns {
  list-style: none;
  padding: 0;
  margin: 0 -1.25rem;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

.columns {
	> * {
	width: calc(100% - 2.5rem);
	margin: 0 1.25rem 2.5rem;
	}

	&--3 {
		> * {
			width: calc(33.33% - 2.5rem);
		}
	}
}

