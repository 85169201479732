$date-card-break-1: 800px;
$date-card-break-2: 550px;

.date-card {
	width: 100%;

	@media (min-width: $date-card-break-1) {
		width: calc(50% - 1.8rem);
	}

	&--secondary,
	&--large {
		background-color: var(--color-three);
		color: var(--color-two);

		.date-card__date {
			background-color: var(--color-one);
			color: var(--color-seven);
			border: 2px solid var(--color-one);
		}

		.date-card__overline {
			color: var(--color-five);
		}

		a {
			&:hover,
			&:focus {
				.button--two {
					border: 1px solid var(--color-six);
					background-color: var(--color-four);
					color: var(--color-one);
				}
			}
		}
	}

	&--large {
		width: 100%;
	}

	a {
		display: block;
		color: inherit;

		&:hover,
		&:focus {
			.date-card__date {
				background-color: #fff;
				color: var(--color-seven);
			}
		}

		@media (min-width: $date-card-break-2) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	&__date {
		width: 9rem;
		height: 9rem;
		background-color: var(--color-five);
		color: var(--color-one);
		border-radius: 100%;
		transition: background-color 0.2s;

		p {
			text-align: center;
			margin: 0;
			padding: 1rem;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.date-card__day {
		display: block;
		margin: auto;
		font-size: 3rem;
		line-height: 1;
	}

	.date-card__month {
		display: block;
		margin: auto;
		font-size: 0.87rem;
		font-weight: 600;
		line-height: 1.35;
	}

	.date-card__year {
		display: block;
		margin: auto;
		font-size: 0.87rem;
		font-weight: 600;
		line-height: 1.35;
	}

	&__time {
		p {
			margin: 0;
		}
	}

	&__text {
		padding-top: 0.8rem;

		@media (min-width: $date-card-break-2) {
			width: calc(100% - 10.8rem);
		}
	}

	&__overline {
		color: var(--color-four);
	}

	&__heading {}
	&__copy {}

	&__categories {

	}

	&__category {
		@include font-preset--15;
		margin: 0 1ex 0 0;
		display: inline-block;

		&:last-of-type {
			margin-right: 0;
		}
	}
}

