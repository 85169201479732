$footer-break-1: 900px;
$footer-break-2: 550px;

.footer {
	margin-top: var(--vertical-block-spacing);
	background-color: var(--color-six);
	color: var(--color-one);
	padding: var(--container-spacing) 0;

	&__container {}

	&__upper {
		padding-bottom: var(--container-spacing);
		border-bottom: 1px solid var(--color-four);
		margin-bottom: var(--container-spacing);

		@media (min-width: $footer-break-1) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	&__logo {
		width: 17rem;
		display: block;
	}

	&__contact-links {
		margin-top: 2rem;

		@media (min-width: $footer-break-1) {
			margin-top: unset;
			width: calc(100% - 19.5rem);
			text-align: right;
		}
	}

	&__contact-links-list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__contact-links-item {
		margin: 0;
	}

	&__contact-links-link {
		@include font-preset--5;
		margin: 0;
		text-decoration: none;
		color: var(--color-four);
		transition: color 0.2s;

		&:hover,
		&:focus {
			color: var(--color-one);
		}
	}

	&__lower {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 -0.9rem;
	}

	&__column {
		width: calc(100% - 1.8rem);
		margin: 0 0.9rem 1.8rem;

		@media (min-width: $footer-break-2) {
			width: calc(33.33% - 1.8rem);
		}

		@media (min-width: $footer-break-1) {
			width: calc(16.66% - 1.8rem);
		}
		
		&--wide {
			width: calc(100% - 1.8rem);
			margin-bottom: 3.6rem;

			@media (min-width: $footer-break-1) {
				width: calc(49.98% - 1.8rem);
			}
		}
	}

	&__heading {
		@include font-preset--9;
		margin-top: 0;
	}

	&__copy {}

	&__nav {}

	&__nav-list {
		list-style: none;
		margin: 0;
		padding: 0;

		li + li {
			margin: 1.2ex 0 0;
		}
	}

	&__nav-item {}

	&__nav-link {
		color: var(--color-four);
		text-decoration: none;
		transition: color 0.2s;

		&:hover,
		&:focus {
			color: var(--color-one);
		}
	}

}
