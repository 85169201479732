.button {
	// Base button - dark text grey bg
	@include font-preset--10;
	display: inline-block;
	appearance: none;
	cursor: pointer;
	text-decoration: none;
	border: 1px solid var(--color-six);
	background-color: var(--color-three);
	color: var(--color-six);
	border-radius: 10ex;
	padding: 1.1ex 3ex;

	&:hover,
	&:focus {
		background-color: var(--color-four);
		color: var(--color-one);
	}

	// Dark background white text
	&--two {
		color: var(--color-one);
		background-color: var(--color-six);
		border: 1px solid var(--color-four);

		&:hover,
		&:focus {
			border: 1px solid var(--color-six);
			background-color: var(--color-four);
			color: var(--color-one);
		}
	}

	&--three {
		color: var(--color-seven);
		background-color: var(--color-four);
		border: 1px solid var(--color-six);
		padding: 1ex 5.2ex;

		&:hover,
		&:focus {
			color: var(--color-one);
			background-color: var(--color-six);
			border: 1px solid var(--color-four);
		}
	}

	&--transparent {
		color: var(--color-six);
		background-color: transparent;
		border: 1px solid transparent;

		&:hover,
		&:focus {
			background-color: transparent;
			border: 1px solid transparent;
			color: var(--color-five);
		}
	}
	
	&--small {
		@include font-preset--15;
	}

	&--code {
		margin-top: 1rex;
	}
}