$location-card-break-1: 900px;
$location-card-break-2: 550px;

.location-card {
	background-color: var(--color-three);
	color: var(--color-two);
	padding: 2.2rem 1.9rem 2.4rem;
	width: calc(100% - 2.24rem);

	@media (min-width: $location-card-break-2) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	@media (min-width: $location-card-break-1) {
		width: calc(50% - 2.24rem);
	}


	&__image {
		width: 9rem;
		height: 9rem;
		border-radius: 100%;
		overflow: hidden;
	}

	&__text {
		padding-top: 0.8rem;

		@media (min-width: $location-card-break-2) {
			width: calc(100% - 10.8rem);
		}
	}

	&__overline {
		color: var(--color-five);
	}

	&__heading {}

	&__icons {}
}

