.content-aside {
  margin: 3.75rem 0 5rem 0;

  h2,
  h3,
  p {
    margin-top: 0; // Remove globally set margins
  }

  .container {
    display: flex;
    flex-direction: column-reverse;
    gap: 2.5rem;
  }

  &__aside,
  &__content {
    width: 100%;
  }

  &__aside {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__heading,
  &__block-heading {
    color: var(--color-seven);
  }

  &__block {
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--color-ten);

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    &--collapse {
      > summary {
        position: relative;
        margin: 0 0 1.25rem 0;

        &::after {
          content: url('/assets/img/eye.svg');
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      &[open] {
        > summary {
          &::after {
            content: url('/assets/img/covered-eye.svg');
          }
        }
      }
    }
  }

  &__block-heading {
    @include font-preset--10;
    padding-right: 1.875rem;
    margin-bottom: 1.25rem;
  }

  &__block-list,
  &__block-list-item {
    padding: 0;
    margin: 0;
  }

  &__block-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__heading {
    @include font-preset--13;
  }

  &__content {
    p {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    margin: 5rem 0 7.5rem 0;
  }

  @media only screen and (min-width: 1024px) {
    .container {
      flex-direction: row;
      gap: 4.125rem;
    }

    &__aside {
      width: 33.3%;
      max-width: 25rem;
    }

    &__content {
      width: 58.3%;
      max-width: 43.75rem;
    }
  }

  @media only screen and (min-width: 1200px) {
    .container {
      gap: 5rem;
    }
  }
}