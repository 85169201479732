@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/open-sans.eot');
	src: url('assets/fonts/open-sans.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/open-sans.woff') format('woff2'),
		url('/assets/fonts/open-sans.woff') format('woff'),
		url('/assets/fonts/open-sans.ttf') format('truetype'),
		url('/assets/fonts/open-sans.svg') format('svg');
	font-weight: 100;
	font-style: regular;
	font-display: swap;
}