.header {
	background-color: var(--color-six);
	z-index: 100;

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		padding-top: 2.5rem;
	}

	&__logo {
		width: 17rem;
		display: block;
		
		@media (max-width: 1250px) {
			padding-right: 3rem;
		}
	}

	&__nav {
		position: absolute;
		width: 100%;
		top: 50%;
		right: var(--container-spacing);
		

		@media (min-width: 1250px) {
			position: static;
			width: calc(100% - 17rem);
		}
	}

	&__line {
		margin-top: 2.5rem;
		width: 100%;
		height: 1px;
		background-color: var(--color-four);
	}
}

