.bold,
p strong {
  font-weight: 800;
}

.italic,
p em {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.upper {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.image-preload {
  height: 0;
}

.error {
	color: var(--color-eleven);
}