.announcement-banner {
	overflow: hidden;
	padding: 1.3rem 0 1.7rem;

	&__marquee {
		white-space: nowrap;
		text-align: center;
		animation-name: marquee;
		animation-duration: 50s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		width: fit-content;
	}

	p {
		@include font-preset--4;
		margin: 0;
		display: inline-block;

		&:not(:last-of-type) {
			padding-right: 2.1ex;

			&::after {
				content: "";
				position: absolute;
				width: 0.4ex;
				height: 0.4ex;
				right: 1.05rem;
				top: 50%;
				transform: translate(50%, -50%);
				border-radius: 100%;
				background-color: var(--color-two);
			}
		}
	}
}


@keyframes marquee {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}
