:root {
	// COLOURS
	--color-one: #fff;
	--color-two: #000;
	--color-three: #F4F2F5;
	--color-four: #BC91CC;
	--color-five: #824C97;
	--color-six: #420863;
	--color-seven: #210363;
	--color-eight: #62B6CB;
	--color-nine: #FDCD83;
	--color-ten: #E9E8EA;
	--color-eleven: #D8513C;
	--color-twelve: #E9B1AA;


	
	// TYPOGRAPHY
	// --font-one: 'Plantin', serif;
	--font-one: 'Manrope', sans-serif;

	// CONTAINER SPACING
	--container-spacing: 1.5rem;

	@media (min-width: 1200px) {
		--container-spacing: 5rem;
	}

	// VERTICAL BLOCK SPACING
	--vertical-block-spacing: calc(var(--container-spacing) * 1.2 );

}

