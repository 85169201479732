.form {
	input,
	select,
	textarea {
		&:not(&.button):not([type=file]) {
			display: block;
			width: 100%;
			padding: 1.2ex;
			border: 1px solid var(--color-four);
			border-radius: 0.375rem;
		}
	}

	label {
		@include font-preset--12;
		margin: 0 0 0.8ex;
		display: block;
		width: 100%;
	}

	&__container {}

	&__heading {
		@include font-preset--2;
	}

	&__intro {
		max-width: 45rem;
	}

	&__sub-heading {
		@include font-preset--6;
		margin: 2.25rem 0 -0.7rem;
	}

	&__sub-sub-heading {
		@include font-preset--7;
		margin: 2.25rem 0 -0.7rem;
	}

	&__row {
		margin: 2rem 0 0;

		&--flex {
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			> div {
				width: calc(50% - 2.18rem);
				margin: 2rem 0 0;
			}
		}

		&--checkbox {
			display: flex;
			justify-content: space-between;

			input {
				width: 1rem !important;
			}

			label {
				@include font-preset--14;
				width: calc(100% - 1.5rem);
				margin: 0;
			}
		}
	}

	&__line {
		max-width: 50rem;
		margin: 2.7rem auto -0.9rem;
		height: 7rem;
		background-image: url('/assets/img/zigzag.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50%;
	}
	
	.button {
		margin-top: 1.1875rem;
	}
}
