.icon-list {
	list-style: none;
	margin: 0;
	padding: 0;

	&__item {}

	&__icon {
		max-width: 1.625rem;
		position: absolute;
		left: 0;
		top: 0.25rem;
	}

	&__heading {
		@include font-preset--9;
		margin: 0;
		padding-left: 2.7rem;
		color: var(--color-five);
	}

	&__copy {
		margin-top: 0.3ex;
	}

	a {
		text-decoration: none;

		.icon-list__heading {
			text-decoration: underline;
		}
	}
}


