$text-block-break-1: 800px;

.text-block {
	margin-top: var(--vertical-block-spacing);

	&__container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column-reverse;

		@media (min-width: $text-block-break-1) {
			justify-content: space-between;
			flex-direction: unset;
		}
	}

	&__text {
		@include font-preset--11;
		margin-top: 2rem;

		@media (min-width: $text-block-break-1) {
			width: calc(66.66% - 4.48rem);
			margin-top: unset;
		}

		&--wide {
			width: 100%;
			max-width: 50rem;
			margin-left: auto;
			margin-right: auto;
		}

		p,
		li {
			font-size: inherit;
		}

		> *:first-child {
			margin-top: 0;
		}
	}

	&__image {
		max-width: 300px;

		@media (min-width: $text-block-break-1) {
			width: 33.33%;
		}
	}

	&__caption {
		@include font-preset--15;
		margin-top: 0.5ex;
	}
}
