.arrow-link {
	@include font-preset--10;
	margin: 0;
	text-decoration: none;
	padding-right: 3ex;
	color: var(--color-five);
	transition: color 0.2s;

	&::after {
		content: '';
		position: absolute;
		right: 0;
		top: calc(50% + 0.22ex);
		transform: translateY(-50%);
		background-image: url('/assets/img/icon-arrow.png');
		background-size: contain;
		background-repeat: no-repeat;
		width: 2ex;
		height: 2ex;
		filter: brightness(1);
		transition: filter 0.2s;
	}

	&:hover,
	&:focus {
		color: var(--color-six);

		&::after {
			filter: brightness(0.5);
		}
		
	}
}
