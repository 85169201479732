$two-col-layout-b-1: 850px;

.two-col-layout {
	margin: var(--vertical-block-spacing) 0;

	&__container {
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;
	}

	&__column-small {
		width: 100%;
		margin-bottom: 2rem;

		@media (min-width: $two-col-layout-b-1) {
			width: calc(33.33% - 3.74rem);
			margin-right: 3.74rem;
		}
	}

	&__column-large {
		width: 100%;

		@media (min-width: $two-col-layout-b-1) {
			width: 66.66%;
			max-width: 600px;
		}
	}

	&__intro {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: baseline;

		h2 {
			margin-right: 2ex;
		}

		p {
			margin: 0;
		}
	}

	&__heading {
		@include font-preset--6;
		margin: 0;
	}

	&__button.button {
		width: 100%;
		margin-top: 5ex;
	}
}
