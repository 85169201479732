.modal {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0,0,0,0);
	pointer-events: none;
	transition: background-color 0.3s;

	&--active {
		background-color: rgba(0,0,0,0.4);
		pointer-events: all;

		.modal__content {
			transform: translateY(0);
			opacity: 1;
		}
	}

	&__container {}

	&__content {
		background-color: var(--color-one);
		padding: 1px 1.5rem 1.5rem;
		border-radius: 1rem;
		transform: translateY(2rem);
		opacity: 0;
		transition: opacity 0.3s, transform 0.5s;

		.form__container {
			padding: 0;
		}

		@media (min-width: 700px) {
			padding: 1px 3rem 3rem;
		}
	}

	&__close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		width: 1.5rem;
		height: 1.5rem;
		appearance: none;
		cursor: pointer;
		border: unset;
		z-index: 1;
		background-image: url('/assets/img/icon-menu-close.png');
		background-size: 1.25rem;
		background-position: 50%;
		background-repeat: no-repeat;
		background-color: transparent;
		filter: invert(1);
	}
}

