.intro {
	margin-top: var(--vertical-block-spacing);
	margin-bottom: calc(1.5rem - var(--vertical-block-spacing));
	z-index: 1;

	+ .form {
		margin-top: var(--vertical-block-spacing);
	}

	&--bg {
		padding-top: var(--container-spacing);
		background: var(--color-three);
		margin-bottom: calc(-1rem - var(--vertical-block-spacing));
	}

	&__overline {
		@include font-preset--13;
		color: var(--color-four);
		display: block;
		margin: 0 0 1ex;
	}

	&__container {}

	&__heading {
		margin: 0;
	}

	&__copy {}
}

