*,
*::before,
*::after {
  box-sizing: border-box;
  position: relative;
}

html {
  background-color: var(--color-one);
  color: var(--color-two);
  font-size: 16px;
  font-family: var(--font-one);
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
  text-rendering: optimizeSpeed;
  font-variant-ligatures: common-ligatures;
  overflow-x: hidden;
  padding: 0;
  scroll-behavior: smooth;
}

.skip-main {
  left: 50%;
  position: absolute;
  top: 0;
  z-index: 2;
  transform: translate(-50%, -100%);
  transition: transform 0.2s;
  margin: 0 !important;
}

.skip-main:focus {
	transform: translate(-50%, 20%);
}

.body {
  &--fixed {
    overflow: hidden;
    height: 100vh;
  }
}

body,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

img,
picture,
svg,
video,
hr {
  width: 100%;
  display: block;
}
