$site-nav-break-1: 1250px;
$site-nav-break-2: 1400px;

// Separare mobile & desktop navs - generally easier to see whats going on if the styling is split out

// DESKTOP NAV
@media (min-width: $site-nav-break-1) {
	.site-navigation {
		position: static;

		&__toggle,
		&__subnav-toggle {
			display: none;
		}


		&__list {
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			align-items: center;
			position: static;
		}

		&__item {
			margin: 0;

			&--has-megamenu {
				position: static;
			}

			&:not(:last-of-type) {
				margin-right: 1.2rem;

				@media (min-width: $site-nav-break-2) {
					margin-right: 1.25rem;
				}
			}
			
			&--icon {
				button {
					appearance: none;
					background: unset;
					border: 0;
					padding: 0;
					cursor: pointer;
					display: block;
				}

				img {
					width: 1.25rem;
				}
			}

			&--spacer {
				@media (min-width: $site-nav-break-2) {
					margin-left: 5rem;
				}
			}

			&:hover,
			&:focus {
				&:before {
					opacity: 1;
					pointer-events: all;
				}

				> .site-navigation__subnav {
					opacity: 1;
					pointer-events: all;
				}
			}
		}

		&__link {
			margin: 0;
			text-decoration: none;
			color: var(--color-one);

			&:hover,
			&:focus {
				&:not(&.button) {
					color: var(--color-four);
				}
			}
		}

		&__subnav-hover-spacer {
			position: absolute;
			bottom: 100%;
			left: 0;
			width: 100%;
			height: 250px;
			z-index: 0;
			background-color: transparent;
		}

		&__subnav {
			opacity: 0;
			pointer-events: none;
			position: absolute;
			z-index: 2;
			top: 50px;
			width: 100%;
			left: 0;
			padding-top: 0.8rem;
			padding-bottom: 4rem;
			display: flex;
			justify-content: space-between;
			background-color: var(--color-one);

			&--small {
				top: 75px !important;
				padding: 0.8rem 1rem;
				width: 220px;

				ul {
					width: 100%;
					max-width: unset;
				}
			}

			&:hover,
			&:focus {
				opacity: 1;
				pointer-events: all;
			}
		}

		&__subnav-col {
			width: 100%;
			max-width: calc(33.33% - 3rem);
			list-style: none;
			padding: 0;
			margin: 0;


			&--feature {}
		}

		&__subnav-heading {
			h2 {
				@include font-preset--9;
				margin: 0;
				color: var(--color-six);
			}
		}

		&__subnav-item {}

		&__subnav-feature {
			h2 {
				@include font-preset--9;
				margin: 0;
				color: var(--color-six);
			}

			a {
				text-decoration: none;
				color: var(--color-six);
			}
		}

		&__subnav-link {
			text-decoration: none;
			color: var(--color-six);
		}
	}
}

// MOBILE NAV
@media (max-width: $site-nav-break-1) {
	.site-navigation {
		&__toggle {
			z-index: 2;
			position: absolute;
			top: 50%;
			right: 0;
			width: 2rem;
			height: 2rem;
			transform: translateY(-50%);
			appearance: none;
			cursor: pointer;
			border: 0;
			padding: 0;
			background-color: transparent;
			background-image: url('/assets/img/icon-menu.png');
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: 1.5rem;

			&--active {
				background-image: url('/assets/img/icon-menu-close.png');
			}
		}

		&__subnav-toggle {
			position: absolute;
			top: 0.5ex;
			right: 0;
			width: 1rem;
			height: 1rem;
			background: red;
			z-index: 2;
			appearance: none;
			cursor: pointer;
			border: 0;
			padding: 0;
			background-color: transparent;
			background-image: url('/assets/img/icon-plus-plain.png');
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: 0.8rem;

			&--active {
				background-image: url('/assets/img/icon-minus-plain.png');
			}
		}


		&__list {
			position: absolute;
			top: -2rem;
			right: calc(0px - var(--container-spacing));
			z-index: 1;
			width: 100%;
			max-width: 30rem;
			height: 100vh;
			overflow: scroll;
			list-style: none;
			margin: 0;
			padding: 3rem 2rem 2rem;
			background: var(--color-six);
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.2s;

			&--active {
				opacity: 1;
				pointer-events: all;
			}
		}

		&__item {
			+ .site-navigation__item {
				margin-top: 3.5ex;
			}

			&--icon {
				button {
					appearance: none;
					background: unset;
					border: 0;
					padding: 0;
					cursor: pointer;
					display: block;
				}

				img {
					width: 1.25rem;
				}
			}
		}

		&__link {
			margin: 0;
			text-decoration: none;
			color: var(--color-one);

			&:hover,
			&:focus {
				&:not(&.button) {
					color: var(--color-four);
				}
			}
		}

		&__subnav-hover-spacer {
			display: none;
		}

		&__subnav {
			padding: 0;
			overflow: hidden;
		}

		&__subnav-col {
			list-style: none;
			padding: 0 0 0 0.8rem;
			margin: 0;

			&--feature {}
		}

		&__subnav-heading {
			h2 {
				@include font-preset--9;
				margin: 0;
				color: var(--color-one);
			}
		}

		&__subnav-feature {
			h2 {
				@include font-preset--9;
				margin: 0;
				color: var(--color-one);
			}

			a {
				text-decoration: none;
				color: var(--color-one);
			}
		}

		&__subnav-item {
			margin: 1.6ex 0 0;
		}

		&__subnav-link {
			text-decoration: none;
			color: var(--color-one);
		}

		

	}

}
