$publication-card-break-1: 800px;
$publication-card-break-2: 550px;

.publication-card {
	width: calc(100% - 2.24rem);
	background-color: var(--color-three);
	color: var(--color-two);


	@media (min-width: $publication-card-break-1) {
		width: calc(50% - 2.24rem);
	}

	&__item-content {
		display: block;

		&:hover,
		&:focus {
			.button--two {
				border: 1px solid var(--color-six);
				background-color: var(--color-four);
				color: var(--color-one);
			}
		}

		@media (min-width: $publication-card-break-2) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	&__thumb {
		width: 9rem;
		height: 9rem;
		background-color: var(--color-one);
		border: 2px solid var(--color-one);
		border-radius: 100%;
		overflow: hidden;
		transition: background-color 0.2s;
	}

	&__text {
		padding-top: 0.8rem;

		@media (min-width: $publication-card-break-2) {
			width: calc(100% - 10.8rem);
		}
	}

	&__overline {
		color: var(--color-five);
	}

	&__heading {
		color: var(--color-two);
	}
	
	&__copy {
		color: var(--color-two);
	}
}
