$pdp-break-one: 700px;

.pdp {
	margin-top: var(--vertical-block-spacing);

	&--cutoff {
		.pdp__price,
		.pdp__discount-message,
		form {
			display: none;
		}
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__mob-heading {
		.pdp__heading {
			margin-bottom: 1.5ex;
		}

		@media (min-width: $pdp-break-one) {
			display: none;
		}
	}

	&__desktop-heading {
		@media (max-width: $pdp-break-one) {
			display: none;
		}
	}

	&__image {
		width: 100%;
		z-index: 2;

		@media (min-width: $pdp-break-one) {
			width: calc(50% - 2.8rem);
		}
	}

	&__details {
		width: 100%;
		z-index: 2;

		@media (min-width: $pdp-break-one) {
			width: 50%;
		}
	}

	&__overline {
		@include font-preset--13;
		margin: 0;
		color: var(--color-five);
	}

	&__heading {
		@include font-preset--2;
		margin-top: 0.7ex;
	}

	&__intro {
		@include font-preset--11;
		margin-top: 1.7ex;
	}

	&__price {
		@include font-preset--9;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 4ex;

		dt {
			font-weight: 400;
		}

		dd {
			font-weight: 800;
		}

		// del {
		// 	// @include font-preset--12;
		// 	// color: red;
		// 	// margin-right: 1rem;
		// }
	}
	
	&__button {
		width: 100%;
		margin-top: 1.25ex;
	}

	&__accordion {
		background: transparent;
		margin-top: 3rem;
		padding: 0;
	}

	&__downloads {
		list-style: none;
		padding: 0;
		margin-top: 0.5rem;

		.cards__item {
			width: 100%;
			margin-top: 1.75rem;
		}
	}

	&__programme {
		display: flex;
		flex-wrap: wrap;
		margin: 1.5rem 0;

		dt {
			font-weight: 800;
			width: 4.3rem;
			border-right: 1px solid;
			padding: 0.5rem 0;

			&:not(&:last-of-type) {
				border-bottom: 1px solid;
			}
		}

		dd {
			font-weight: 400;
			width: calc(100% - 4.3rem);
			padding: 0.5rem 0 0.5rem 1rem;

			&:not(&:last-of-type) {
				border-bottom: 1px solid;
			}
		}
	}
	
	&__background-shape {
		top: -10rem;
		left: -15rem;
		width: 61vw;
		z-index: 1;
	}
}
