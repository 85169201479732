.hero-small {
	background-color: var(--color-six);
	overflow: hidden;

	&__container {
		padding-top: var(--container-spacing);
		padding-bottom: var(--container-spacing);
	}

	&__text {
		max-width: 46rem;
		color: var(--color-one);
		z-index: 2;
	}

	&__overline {
		@include font-preset--13;
	}

	&__heading {
		@include font-preset--3;
		margin-top: 0.7ex;
	}

	&__intro {
		@include font-preset--8;
		margin-top: 2ex;
		color: var(--color-four);
	}

	&__button {
		margin: 2.9ex 0 0;
	}

	&__background-shape {
		top: 6.5rem;
		right: -19%;
		width: 63%;
	}
}
