.basket {
	background-color: var(--color-three);
	padding: 1.8rem;

	&__heading {
		@include font-preset--6;
		margin: 0;
	}

	&__info {
		color: var(--color-five);
		margin: 0;

		li + li {
			margin: 0.5ex 0 0;
		}
	}

	&__sub-list {
		list-style: none;
		margin: 2.4rem 0 0;
		padding: 0;
	}
	
	&__sub-list-item {
		margin: 0;
		display: flex;
		justify-content: space-between;

		&:not(&:first-of-type) {
			border-top: 1px solid #DBD9DC;
			padding-top: 0.8ex;
		}

		p {
			margin: 0 0 0.8ex;

			&:first-of-type {
				margin-right: 1ex;
				font-weight: 800;
			}
			
			span {
				display: block;
			}
		}
	}
}