$image-row-b-1: 720px;

.image-row {
	margin-top: var(--vertical-block-spacing);

	&__container {}

	&__cols {
		display: flex;
		align-items: flex-start;
		margin: 0 -0.5rem 0;

		@media (min-width: $image-row-b-1) {
			margin: 0 -1rem 0;
		}
	}
	
	&__image {
		margin: 0 0.5rem;

		&--natural {
			img {
				width: unset;
				max-width: 100%;
				margin: auto;
			}
		}

		@media (min-width: $image-row-b-1) {
			margin: 0 1rem;
		}
	}
}
