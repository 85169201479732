.mini-filter {
	width: 100%;
	margin-bottom: 3rem;

	&__heading {
		@include font-preset--12;
		display: inline;
		padding-right: 1ex;
	}

	&__item {
		display: inline;
		padding-right: 1ex;
		color: var(--color-six);

		&--active {
			text-decoration: none;
			pointer-events: none;
			color: var(--color-five);
		}
	}
}
