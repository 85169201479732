$team-card-break-1: 800px;
$team-card-break-2: 550px;

.team-card {
	width: calc(100% - 2.24rem);

	@media (min-width: $team-card-break-1) {
		width: calc(50% - 2.24rem);
	}

	&--light {
		background-color: var(--color-three);
		color: var(--color-two);
		
		.team-card__overline {
			color: var(--color-five);
		}

		.team-card__question {
			color: var(--color-two);
		}
	}

	> div {
		padding: 2.2rem 1.9rem 2.4rem;
		display: block;
		height: 100%;
		
		@media (min-width: $team-card-break-2) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	&__image {
		width: 5.6rem;
		height: 5.6rem;
		border-radius: 100%;
		overflow: hidden;
	}

	&__text {
		width: 100%;
		margin-top: 1.4rem;

		@media (min-width: $team-card-break-2) {
			width: calc(100% - 7.4rem);
			margin-top: -0.25rem;
		}
	}

	&__overline {
		color: var(--color-four);
	}

	&__heading {}

	&__copy {
		margin: 2ex 0 0;
	}

	&__accordion {
		.accordion__button {
			@include font-preset--14;
			color: var(--color-one);
			background-image: unset;
			text-decoration: underline;
			margin-top: 2ex;

			&::before {
				content: "Read More";
			}

			&--active {
				&::before {
					content: "Read Less";
				}
			}
		}
	}

	&__question {
		@include font-preset--14;
		color: var(--color-one);
		margin-top: 2ex;
		display: block;
	}
}




