.button-list {
	list-style: none;
	margin: 0;
	padding: 0;

	&__item {
		display: inline-block;
		margin: 0;

		&:not(:last-of-type) {
			margin-right: 3ex;
		}
	}
}
