.search {
  overflow: hidden;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &__form {
    width: calc(100% - 1.8rem);
  }

  &__input {
    width: 100%;
    padding: 0.2rem 0.5rem!important;
  }

  @media only screen and (min-width: 1251px) {
    width: fit-content;

    &__input {
      width: 0%;
      max-width: 8.75rem;
      height: 0;
      transition: width 300ms linear;
    }

    &__form {
      width: 0;
      height: 0;
      margin: 0;
      overflow: hidden;
  
      &.visible {
        width: 100%;
        height: auto;
  
        .search__input {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}