.image-card {
	width: calc(100%);

	@media (min-width: 740px) {
		width: calc(33.33% - 1.8rem);
	}

	a {
		padding: 0;
		display: block;
		background-color: var(--color-five);
		transition: background-color 0.2s;
		height: 100%;
		color: inherit;

		&:hover,
		&:focus {
			background-color: var(--color-six);

			.image-card__image img {
				transform: scale(1.06);
			}
		}
	}

	&__image {
		border-radius: 1rem 1rem 0 0;
		overflow: hidden;

		img {
    		transition: transform linear 0.4s;
		}
	}

	&__text {
		padding: 1.56rem 1.9rem 1.85rem;
	}

	.image-card__overline {
		@include font-preset--15;
	}

	&__heading {}

	&__button {
		width: 100%;
		text-align: center;
	}

	&__copy {
		margin-top: 0.5ex;
	}

	&--static {
		pointer-events: none;

		a {
			background-color: var(--color-three);
		}

		.image-card__text {
			color: var(--color-two);
		}

		.image-card__overline {
			color: var(--color-four);
		}
	}
}

