.category-filter {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0;

    &__item {
        margin: 0 1rem;
    }
}