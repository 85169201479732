.icon-links {
	list-style: none;
	margin: 0;
	padding: 0;

	&__item {
		@include font-preset--10;

		a {
			color: var(--color-five);
			transition: color 0.2s;

			&:hover,
			&:focus {
				color: var(--color-six);
			}
		}
	}

	&__icon {
		position: absolute;
		left: 0;
		top: 0.4ex;
		width: 2.2ex;
		height: 2.2ex;
	}

	&__text {
		font: inherit;
		margin: 0;
		padding-left: 4ex;
	}
}