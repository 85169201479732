.quiz {
	background-color: var(--color-three);
	padding: calc(var(--vertical-block-spacing) * 2) 0;
	overflow: hidden;

	&--reveal {

		.quiz__answer[value='1'] {
			+ .quiz__answer-toggle {
				background-color: var(--color-four);
				border-color: var(--color-seven);
			}
		}

		.quiz__answer--selected[value='0'] {
			+ .quiz__answer-toggle {
				background-color: var(--color-twelve);
				border-color: var(--color-eleven);
			}
		}

	}

	&__container {}

	&__heading {
		@include font-preset--6;
		margin: 0;
		z-index: 2;
	}

	&__intro {
		z-index: 2;
	}

	&__content {
		z-index: 2;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__item {
		background-color: var(--color-one);
		padding: 2.25rem 3rem 3rem;
		border-radius: 1rem;
		margin-top: 3rem;
		opacity: 0;
		transform: translateY(2rem);
		transition: 0.4s transform 1s, 0.4s opacity 1s;
		position: absolute;

		&--first {
			opacity: 1;
			transform: translateY(0);
			z-index: 2;
			position: relative;
		}

		&--selected {
			z-index: 2;
			position: relative;

			.quiz__answers {
				cursor: not-allowed;
			}

			.quiz__answer-wrapper {
				pointer-events: none;
			}

			+ .quiz__item {
				opacity: 1;
				transform: translateY(0);
				position: relative;
			}
		}

		+ .quiz__item {
			margin-top: 2.5rem;
		}
	}

	&__overline {
		@include font-preset--10;
		color: var(--color-five);
		margin: 0;
	}

	&__question {
		@include font-preset--5;
		margin: 0;
	}

	&__detail {
		margin-top: 1.8ex;
	}

	&__image {
		margin-top: 1rem;
	}

	&__answers {
		margin-top: 1rem;
	}

	&__answer-wrapper {
		display: flex;
		align-items: center;
		margin-top: 1.25rem;
		cursor: pointer;
	}

	&__answer {
		position: absolute;
		opacity: 0;

		&:focus {
			+ .quiz__answer-toggle {
				border-color: var(--color-seven);
			}
		}

		&:checked {
			+ .quiz__answer-toggle {
				background-color: var(--color-four);
				border-color: var(--color-seven);
			}
		}
	}

	&__answer-toggle {
		pointer-events: none;
		width: 1.3rem;
		height: 1.3rem;
		border-radius: 0.2rem;
		display: block;
		border: 1.5px solid var(--color-four);
		margin-right: 0.8rem;
	}

	fieldset {
		border: 0;
		padding: 0;
		margin: 0;
	}
	

	&__background-shape {
		top: 10rem;
		right: unset;
		left: -15rem;
		width: 40rem;
		bottom: unset;

		&--two {
			top: calc(100% - 25rem);
			right: -15rem;
			left: unset;
			width: 60rem;
		}
	}

	&__result {
		border: 2px solid var(--color-five);
		border-radius: 0.25rem;
		margin-top: 0.6rem;
	}

	&__result-bar {
		background-color: var(--color-five);
		width: 0;
		height: 2.8rem;
		transition: 0.5s width 1.6s;
	}

	&__result-count {
		@include font-preset--12;
		margin-top: 0.4ex;
	}

	&__buttons {}

	&__show-results {
		@include font-preset--10;
		display: inline-block;
		appearance: none;
		cursor: pointer;
		text-decoration: none;
		border: 0;
		background-color: transparent;
		color: var(--color-six);
		padding: 1.1ex 3ex;

		&:hover,
		&:focus {
			color: var(--color-four);
		}
	}
}
