.icon-card {
	width: calc(100% - 2.24rem);

	@media (min-width: 740px) {
		width: calc(33.33% - 2.24rem);
	}

	a {
		display: block;
	}

	> div {
		padding: 2.2rem 1.9rem 2.4rem;
		text-decoration: none;
	}

	&__image {
		max-width: 6.25rem;
	}

	&__text {}

	.icon-card__heading {
		margin-top: 2ex;
	}
}
