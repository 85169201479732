$hero-break-1: 1000px;
$hero-break-2: 700px;

.hero {
	background-color: var(--color-six);
	overflow: hidden;

	&__container {
		display: flex;
		flex-direction: column-reverse;
		padding-top: calc(var(--container-spacing) / 2);
		padding-bottom: calc(var(--container-spacing) / 2);
		
		@media (min-width: $hero-break-2) {
			justify-content: space-between;
			align-items: flex-start;
			flex-direction: unset;
		}

		@media (min-width: $hero-break-1) {
			align-items: center;
		}
	}

	&__text {
		color: var(--color-one);
		z-index: 2;

		@media (min-width: $hero-break-2) {
			width: calc(66.66% - 0.93rem);
		}

		@media (min-width: $hero-break-1) {
			width: calc(50% - 0.93rem);
		}
	}

	&__heading {
		@include font-preset--1;
		margin-top: 1ex;

		@media (min-width: $hero-break-2) {
			font-size: 2.8rem;
			margin-top: 0;
			// width: calc(66.66% - 0.93rem);
		}
	}

	&__intro {
		@include font-preset--11;
	}

	&__buttons {
		margin: 2.9ex 0 0;
	}

	&__image {
		max-width: 250px !important;
		overflow: hidden;
		border-radius: 100%;
		z-index: 2;

		@media (min-width: $hero-break-2) {
			width: calc(33.33% - 0.93rem);
			max-width: unset;
			max-width: 400px !important;
		}

		@media (min-width: $hero-break-1) {
			width: calc(50% - 3.4rem);
		}
	}

	&__background-shape {
		width: 63%;
	}
}



