.info-cards {
	max-width: 36rem;
    list-style: none;
    margin: 0;
    padding: 0;

	&__item {
		padding: 1rem 2rem 2rem;
		background-color: var(--color-three);
		border-radius: 1rem;
		margin-top: 2.5rem;

		&:first-of-type {
			margin-top: 0;
		}
	}

	&__heading {
		@include font-preset--6;
		margin: 0;
	}

	&__bullets {
		color: var(--color-five);

		li + li {
			margin: 0.6ex 0 0;
		}
	}

	&__details {
		margin: 2.62ex 0 0;
		display: flex;
		flex-wrap: wrap;

		dt {
			font-weight: 600;
			margin-right: 0.8ex;
		}

		span {
			display: block;
			width: 100%;
			margin-top: 0.3rem;
		}
	}

	&__copy {
		@include font-preset--11;
		margin: 1.6ex 0 0;
	}

	&__subheading {
		@include font-preset--12;
	}

	&__passcode {
		border: 1px solid var(--color-four);
		background-color: var(--color-one);
		padding: 1.5ex;
		border-radius: 0.375rem;
		margin-top: 0.4ex;
		color: #6a6a6a;
	}

	&__link {
		@include font-preset--12;
		display: flex;
		text-decoration: none;
		color: var(--color-five);
		margin-top: 3.5ex;
		align-items: center;

		+.info-cards__link {
			margin-top: 1.5ex;
		}

		picture {
			max-width: 1.25rem;
			margin-right: 0.8rem;
		}
	}

	&--confirmation {
		margin: 2rem auto;

		.info-cards__item:first-of-type {
			margin-top: 2rem;
		}

		.info-cards__copy {
			@include font-preset--14;
			margin: 0;

			&:first-of-type {
				margin-top: 1rem;
			}	
		}
	}
}
