.notice {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: none;
  padding-left: 0;

  li {
    padding: 0.8rem 1rem;
    border-radius: 1rem;
    width: 100%;
    border: 2px solid transparent;
  }

  &--errors {
    li {
      background-color: #FDCD8380;
      border-color: var(--color-nine);
    }
  }

  &--message {
    li {
      background-color: #62B6CB80;
      border-color: var(--color-eight);
    }
  }
}