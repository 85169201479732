.account {
	padding-top: 2rem;

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		// align-items: baseline;
		max-width: 75rem;
		margin-top: 2rem;

		.container {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__nav,
	&__content {
		width: 100%;
	}

	&__nav-list {
		background-color: var(--color-three);
		border-radius: 1rem;
		padding: 0.01rem 1.8rem 1.8rem;
		margin: 0;
		list-style: none;
	}

	&__nav-heading {
		h2 {
			@include font-preset--12;
			margin: 0;
		}
	}

	&__nav-item {

		+.account__nav-heading {
			margin-top: 1.5rem;
		}

		&--space-above {
			margin-top: 2.5rem;
		}

		a {
			color: var(--color-six);
		}

		&--active {
			a {
				text-decoration: none;
				color: var(--color-five);
			}
		}
	}

	

	&__form {
		max-width: 35rem;

		&--center {
			margin: auto;
			max-width: 40rem;
		}
	}


	&__sub-heading {
		font-size: 1.5rem;
		color: var(--color-seven);
	}

	&__receipt-table {
		margin: 0.5rem 0 0;
	}

	.form__container {
		.form__heading {
			&:first-of-type {
				margin-top: 0;
			}
		}
	}


	@media only screen and (min-width: 760px) {
		&__container {
			gap: 2.7rem;
		}
		
		&__nav {
			width: calc(25% - 1.375rem);
		}

		&__content {
			width: calc(75% - 1.375rem);
		}
	}
}
