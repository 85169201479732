$asset-card-break-1: 800px;
$asset-card-break-2: 550px;

.asset-card {
	width: calc(100% - 2.24rem);

	@media (min-width: $asset-card-break-1) {
		width: calc(50% - 2.24rem);
	}

	a {
		display: block;
		height: 100%;
		transition: background-color 0.2s;
		background-color: var(--color-three);
		padding: 1.2rem 1rem 1.4rem !important;
		
		@media (min-width: $asset-card-break-2) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&:hover,
		&:focus {
			background-color: var(--color-six);

			.asset-card__text {
				color: var(--color-one);
			}
		}
	}

	&__image {
		width: 4.6rem;
		height: 4.6rem;
		border-radius: 100%;
		overflow: hidden;
		background-color: var(--color-five);
	}

	.asset-card__text {
		width: 100%;
		margin-top: 1.4rem;
		color: var(--color-two);
		transition: color 0.2s;

		h3 {
			@include font-preset--9;
		}

		@media (min-width: $asset-card-break-2) {
			width: calc(100% - 5.75rem);
			margin-top: -0.25rem;
		}
	}

	&__copy {
		margin: 0;

		&--cta {
			text-decoration: underline;
		}
	}
}

