.background-shape {
	position: absolute;
	top: -5rem;
	right: 2rem;
	pointer-events: none;
	z-index: 1;
	animation-name: pulse;
	animation-duration: 25s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: linear;
}


@keyframes pulse {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.2);
	}
}



