// This is all a bit ugly, but the only way achieve the correct styling with html tables that might be pasted in by the client. eg - no classes

:root {
	--table-border-radius: 0.5rem;
}

table,
th,
td {
  border: 1px solid var(--color-five);
  text-align: left;
}


td {
	@include font-preset--14;
}

td,
th {
	padding: 0.8rem;
	vertical-align: top;
	background-color: var(--color-one);

	a {
		color: var(--color-five) !important;
	}
}

table {
  border-spacing: 0;
  margin: 2rem 0 0;
  width: 100%;
  border: 0;
}

th {
	@include font-preset--12;

	&:first-of-type {
		border-radius: var(--table-border-radius) 0 0 0;
	}

	&:last-of-type {
		border-radius: 0 var(--table-border-radius) 0 0;
	}
}

tr {
    th,
    td {
      &::before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: var(--color-one);
      }

      &:not(:last-of-type) {
        &::after {
          content: '';
          position: absolute;
          left: unset;
          bottom: unset;
          top: 0;
          right: -1px;
          height: calc(100% + 1px);
          width: 1px;
          background-color: var(--color-one);
        }
      }
    }

    &:last-of-type {
      th,
      td {
        &::before {
          display: none;
        }

        &:first-of-type {
          border-radius: 0 0 0 var(--table-border-radius);
        }

        &:last-of-type {
          border-radius: 0 0 var(--table-border-radius) 0;
        }
      }
    }
}


