.entry-team {
  margin: var(--container-spacing) 0;
  
  .container,
  &__group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.875rem;
  }

  .team-card {
    margin: 0;
    flex-grow: 1;
  }

  &__heading,
  &__group,
  &__group-heading {
    width: 100%;
  }

  &__heading {
    margin: 0;
    text-align: center;
  }

  &__group-heading {
    @include font-preset--13;
    color: rgba(0,0,0,0.7);
  }

  @media only screen and (min-width: 1024px) {
    &__group {
      justify-content: space-between;
    }
  }
}