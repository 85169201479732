.team-members {
  margin: var(--container-spacing) 0;
  // All / Mobile
  &__heading,
  &__intro {
    max-width: 50rem;
  }

  &__intro {
    margin-bottom: 2.75rem;
  }

  &__lgmembers,
  &__mdmembers,
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
    padding: 0;
    margin-bottom: 1.875rem;
  }

  &__list {
    list-style-type: none;
    padding: 0;
  }

  &__item-heading {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5;
    margin-top: 0;
  }

  &__item-text {
    margin-top: 0.9375rem;
  }

  .team-card {
    width: 100%;
    margin: 0;
    flex-grow: 1;
  }

  // Tablet
  @media only screen and (min-width: 768px) {
    &__mdmembers,
    &__list {
      flex-direction: row;
      align-items: stretch;
      flex-wrap: wrap;

      .team-card {
        width: calc(50% - 0.9375rem);

        &__text {
          width: 100%;
        }
      }
    }
  }

  // Desktop
  @media only screen and (min-width: 1024px) {
    &__lgmembers {
      flex-direction: row;
      align-items: stretch;
      flex-wrap: wrap;

      .team-card {
        width: calc(50% - 0.9375rem);
      }
    }

    &__mdmembers {
      .team-card {
        width: calc(33% - 0.9375rem);
      }
    }
  }
}