.accordion {
	margin-top: var(--vertical-block-spacing);
	background: var(--color-three);
	padding-top: var(--container-spacing);
	padding-bottom: var(--container-spacing);

	&__container {}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		border-bottom: 1px solid var(--color-four);
		padding-bottom: 1rem;
	}

	&__heading {
		@include font-preset--9;
		margin: 0;
	}

	&__button {
		font: inherit;
		appearance: none;
		background: transparent;
		border: 0;
		width: 100%;
		margin: 0;
		padding: 0;
		text-align: left;
		cursor: pointer;
		background-image: url('/assets/img/icon-plus.png');
		background-size: 2ex;
		background-position: 100% 50%;
		background-repeat: no-repeat;

		&--active {
			background-image: url('/assets/img/icon-minus.png');
		}
	}

	&__draw {
		overflow: hidden;
		transition: height 0.3s;
	}
}
