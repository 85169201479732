.heading {
  margin-top: var(--vertical-block-spacing);
  margin-bottom: -0.25rem;

  &__heading,
  &__intro {
    max-width: 43.75rem;
  }

  &__intro {
    margin: 1.4rem 0;
  }

  @media only screen and (min-width: 1200px) {
    margin-bottom: -3.75rem;
  }

  &--center {
    text-align: center;

    .heading__heading,
    .heading__intro {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--mb {
    margin-bottom: 1.5rem;
  }
}