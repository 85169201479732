.search-results {
  &__empty {
    margin-top: 4rem;
    background-color: var(--color-three);
    padding: 2.2rem 1.9rem 2.4rem;
    border-radius: 1rem;
  }

  &__heading {
    margin-top: 0;
  }

  &__copy {
    margin-bottom: 1rem;
  }
}
