.card {
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem;

  p {
    margin: 0;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__overline {
    @include font-preset--12;
    color: var(--color-four);
  }

  &__strapline {
    @include font-preset--15;
  }

  &__title {
    @include font-preset--10;
  }
  
  @media only screen and (min-width: 768px) {
    padding: 1.5rem;
  }

  @media only screen and (min-width: 1024px) {
    &__overline {
      @include font-preset--10;
    }

    &__title {
      @include font-preset--7;
    }
  }

  &--light {
    background-color: var(--color-three);
  }

  &--dark {
    background-color: var(--color-six);

    .card__title,
    .card__strapline {
      color: var(--color-one);
    }
  }

  &--aside {
    display: flex;
    flex-wrap: nowrap;
    gap: 1.25rem;
    margin-bottom: 0.625rem;

    .card__picture {
      width: 3.75rem;
      height: 3.75rem;
      overflow: hidden;
      border-radius: 6rem;
    }

    .card__content {
      width: calc(100% - 5rem);
      flex-grow: 1;
    }

    @media only screen and (min-width: 768px) {
      .card__picture {
        width: 5.625rem;
        height: 5.625rem;
      }

      .card__content {
        width: calc(100% - 6.875rem);
      }
    }
  }
}