.cards {
	margin-top: var(--vertical-block-spacing);

	&--with-feature {
		@media (min-width: 740px) {
			.cards__item {
				width: calc(50% - 1.8rem);

				&:nth-of-type(1) {
					width: 100%;
				}
			}
		}
	}

	&--iotm {
		h3.image-card__heading {
			font-size: 1.3rem;
		}
	}

	&__container {}

	&__heading {
		@include font-preset--2;
		text-align: center;
		margin: 0 0 2.24rem 0;
	}

	&__columns {
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: 0 -0.9rem;
	}	

	&__item {
		width: calc(50% - 1.8rem);
		margin: 0 0.9rem 1.8rem;
		border-radius: 1rem;
		background-color: var(--color-six);
		color: var(--color-one);
		overflow: hidden;

		> a,
		> .cards__item-content {
			padding: 2.2rem 1.9rem 2.4rem;
			text-decoration: none;
		}

		h3 {
			@include font-preset--7;
			margin: 0;
		}

		span {
			@include font-preset--10;
		}
	}

	&__item-more-link {
		width: calc(100% - 2.24rem);
		margin: 2.24rem 1.12rem;
		text-align: right;
	}

	&__item-heading {
		width: calc(100% - 2.24rem);
		margin: 4.5rem 1.12rem 0;

		hr {
			border-top: 1px solid var(--color-four);
			margin: 0;
		}

		h2 {
			@include font-preset--6;
			margin-top: 3.5ex;
		}
	}

	&__item-tag {
		position: absolute;
		top: 0.5rem;
		right: 1rem;
		color: var(--color-four);
	}
}

